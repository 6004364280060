import React from "react";
import Layout from "../../components/layout/Layout";
import { useStaticQuery, graphql } from "gatsby";
import ImageSection from "../../components/ui/sections/ImageSection";
import TagsSection from "../../components/ui/sections/TagsSection";

const PerfumcityPage = () => {
  const query = useStaticQuery(graphql`
    query {
      articleImg: file(relativePath: { eq: "portfolio/perfumcity.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `);

  const tags = [
    "Prestashop",
    "wdrożenie audytu SEO",
    "integracja z hurtowniami",
    "nowoczesny projekt graficzny",
    "intuicyjny interfejs użytkownika",
    "system zarządzania treścią",
  ];

  return (
    <Layout pageTitle="perfumcity.pl">
      <main className="container mx-auto px-5">
        <ImageSection
          subtitle="Platforma Prestashop"
          title="Pachnący e-commerce"
          image={query.articleImg.childImageSharp.gatsbyImageData}
        >
          <p className="text-gray-700 text-base mb-4">
            Dla klienta z branży kosmetycznej przygotowaliśmy w pełni
            zautomatyzowany sklep internetowy. Produkty i stany magazynowe są
            synchronizowane z hurtownią, faktury wystawiają się same. Klient
            może skupić się na swoim biznesie i pracować nad sprzedażą.
          </p>
          <p className="text-gray-700 text-base mb-4">
            Zakupiony przez klienta szablon graficzny został dostosowany do jego
            potrzeb. Wyeliminowaliśmy również problem SPAMu w formularzach
            rejestracji oraz kontaktu który dotknął klienta.
          </p>
        </ImageSection>
        <TagsSection title="Projekt w pigułce" tags={tags} />
      </main>
    </Layout>
  );
};

export default PerfumcityPage;
